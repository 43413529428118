import React from 'react';
import ProductCard from './ProductCard';

function ProductRow({ products, cartItems, setCartItems }) {
  return (
    <div className="grid grid-cols-1 gap-4 mb-6">
      {products.map((product) => (
        <ProductCard
          product={{
            product_id: product.product_id,
            product_name: product.product_name,
            product_description: product.product_description,
            price: product.price,
            product_pic: product.product_pic,
            product_type: product.product_type, // Make sure product_type is included
          }}
          cartItems={cartItems}
          setCartItems={setCartItems}
        />
      ))}
    </div>
  );
}

export default ProductRow;
