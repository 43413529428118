// src/utils/cookieUtils.js

export function setCookie(name, value, days) {
    const expires = days
      ? `; expires=${new Date(Date.now() + days * 86400000).toUTCString()}`
      : '';
    document.cookie = `${name}=${encodeURIComponent(value || '')}${expires}; path=/`;
  }
  
  export function getCookie(name) {
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${name}=`));
    return cookieValue ? decodeURIComponent(cookieValue.split('=')[1]) : null;
  }
  