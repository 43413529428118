import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ProductRow from './components/ProductRow';
import CartModal from './components/CartModal';
import Categories from './components/Categories';

import { getCookie, setCookie } from './utils/cookieUtils';

function App() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [showCartModal, setShowCartModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [telegramUser, setTelegramUser] = useState(null); // Store Telegram user data
  const [touchStart, setTouchStart] = useState(0); // State for touch start position

  const tg = window.Telegram?.WebApp;

  const updateMainButton = (cartItems) => {
    if (cartItems.length > 0) {
      tg.MainButton.setText('Корзина');
      tg.MainButton.show();
    } else {
      tg.MainButton.hide();
    }
  };

  useEffect(() => {
    if (tg) {
      tg.ready();

      // Retrieve user data from Telegram
      const initDataUnsafe = tg.initDataUnsafe;
      setTelegramUser(initDataUnsafe?.user);

      // Initialize cart from cookies
      const cartCookie = getCookie('cart');
      const initialCart = cartCookie ? JSON.parse(cartCookie) : [];
      setCartItems(initialCart);
      updateMainButton(initialCart);

      tg.onEvent('mainButtonClicked', () => {
        setShowCartModal(true);
      });
    } else {
      console.log('Приложение не запущено внутри Telegram');
    }

    axios
      .get('/api/products')
      .then((response) => {
        setProducts(response.data);
        setFilteredProducts(response.data);
      })
      .catch((error) => {
        console.error('Ошибка при получении продуктов:', error);
      });

    return () => {
      if (tg) {
        tg.offEvent('mainButtonClicked', () => {
          setShowCartModal(true);
        });
      }
    };
  }, []);

  useEffect(() => {
    if (tg) {
      updateMainButton(cartItems);
    }
    setCookie('cart', JSON.stringify(cartItems), 7);
  }, [cartItems]);

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query === '') {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter((product) =>
        product.product_name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredProducts(filtered);
    }
  };

  // Handle touch start event
  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientY);
  };

  // Handle touch move event and detect swipe down
  const handleTouchMove = (e) => {
    const touchEnd = e.touches[0].clientY;
    const inputElement = document.getElementById('search-input'); // Get the input element

    // Detect swipe down gesture (you can adjust the threshold value if needed)
    if (touchStart - touchEnd < -50) {
      inputElement.blur(); // Close the keyboard by blurring the input
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.target.blur(); // Close the keyboard
    }
  };

  const renderProductRows = () => {
    const rows = [];
    for (let i = 0; i < filteredProducts.length; i += 3) {
      const productRow = filteredProducts.slice(i, i + 3);
      rows.push(
        <ProductRow
          key={i}
          products={productRow}
          cartItems={cartItems}
          setCartItems={setCartItems}
        />
      );
    }
    return rows;
  };

  return (
    <div
      className="min-h-screen"
      style={{
        backgroundColor: 'var(--tg-theme-bg-color, #ffffff)',
        color: 'var(--tg-theme-text-color, #000000)',
      }}
    >
      <div className="container mx-auto py-8">
        {/* Search Product Component */}
        <div className="mb-4">
          <input
            type="text"
            id="search-input" // Add ID for easy access in JavaScript
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            onTouchStart={handleTouchStart} // Listen for touch start
            onTouchMove={handleTouchMove} // Listen for touch move to detect swipe down
            onKeyPress={handleKeyPress} // Listen for Enter key
            placeholder="Поиск товаров..."
            className="w-full p-3 border rounded"
            style={{
              backgroundColor: 'var(--tg-theme-secondary-bg-color, #ffffff)',
              color: 'var(--tg-theme-text-color, #000000)',
            }}
          />
        </div>
        <Categories setFilteredProducts={setFilteredProducts} />
        {/* Render Product Rows */}
        {renderProductRows()}
      </div>
      {showCartModal && (
        <CartModal
          onClose={() => setShowCartModal(false)}
          cartItems={cartItems}
          setCartItems={setCartItems}
          telegramUser={telegramUser} // Pass Telegram user data to the modal
        />
      )}
    </div>
  );
}

export default App;
