import React, { useState, useEffect } from 'react';
import axios from 'axios';

function ProductCard({ product, cartItems, setCartItems }) {
  const [quantity, setQuantity] = useState(0);
  const [stock, setStock] = useState(0);

  const tgThemeParams = window.Telegram.WebApp.themeParams || {};

  useEffect(() => {
    // Fetch initial stock when the component loads
    fetchStock();
  }, [product.product_id]);

  const fetchStock = () => {
    axios
      .get(`/api/products/${product.product_id}/stock`)
      .then((response) => {
        setStock(response.data.stock);
      })
      .catch((error) => {
        console.error('Error fetching stock:', error);
      });
  };

  const handleDecrease = () => {
    setQuantity((prevQuantity) => (prevQuantity > 0 ? prevQuantity - 1 : 0));
  };

  const handleIncrease = () => {
    if (quantity < stock) {
      setQuantity((prevQuantity) => prevQuantity + 1);
    }
  };

  const handleAddToCart = () => {
    // Fetch stock from the server before adding to the cart to ensure it's accurate
    axios
      .get(`/api/products/${product.product_id}/stock`)
      .then((response) => {
        const currentStock = response.data.stock;
        const cartItem = cartItems.find((item) => item.product_id === product.product_id);
        const cartQuantity = cartItem ? cartItem.quantity : 0;
  
        // Calculate available stock by subtracting items already in the cart
        const availableStock = currentStock - cartQuantity;
  
        if (quantity > availableStock) {
          alert(`You can't add more than ${availableStock} items. Only ${availableStock} more are available.`);
          return;
        }
  
        // Prepare new cart items
        const totalQuantity = cartQuantity + quantity;
        let newCartItems = [...cartItems];
  
        const existingItemIndex = cartItems.findIndex(
          (item) => item.product_id === product.product_id
        );
  
        if (existingItemIndex !== -1) {
          // Update quantity for the existing item in the cart
          newCartItems[existingItemIndex].quantity = totalQuantity;
        } else {
          // Add new item to the cart
          newCartItems.push({
            product_id: product.product_id,
            product_name: product.product_name,
            price: product.price,
            quantity: quantity,
            product_type: product.product_type
          });
        }
  
        // Update cart items and stock
        setCartItems(newCartItems);
        setStock(currentStock - totalQuantity); // Update stock
        setQuantity(0); // Reset quantity after adding to cart
      })
      .catch((error) => {
        console.error('Error checking stock:', error);
      });
  };
    
  // Hide product card if stock is 0
  if (stock === 0) {
    return null;
  }

  return (
    <div
    className="shadow-md rounded-lg overflow-hidden flex flex-col"
    style={{
        backgroundColor: stock > 0 
          ? tgThemeParams.bg_color || 'var(--tg-theme-secondary-bg-color, #f8f9fa)' 
          : '#f8d7da',  // Red background for out-of-stock items
        color: stock > 0 ? 'inherit' : '#721c24',  // Red text for out-of-stock items
      }}
    >
      <div style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
        {product.product_pic && (
          <img
            src={product.product_pic}
            alt={product.product_name}
            style={{
              width: 'auto',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
              display: 'block',
              margin: '0 auto',
            }}
          />
        )}
      </div>
      <div className="p-4 flex flex-col flex-grow">
        <div className="flex-grow">
          <h2
            className="text-xl font-semibold mb-2"
            style={{ color: tgThemeParams.text_color || 'var(--tg-theme-text-color, #000000)' }}
          >
            {product.product_name}
          </h2>
          <p
            className="mb-4"
            style={{ color: tgThemeParams.hint_color || 'var(--tg-theme-hint-color, #6c757d)' }}
          >
            {product.product_description}
          </p>
          <div className="flex items-center justify-between mb-4">
            <p
              className="text-lg font-bold"
              style={{ color: tgThemeParams.link_color || 'var(--tg-theme-link-color, #007bff)' }}
            >
              {product.price} тг
            </p>
            <p
              className="text-sm"
              style={{ color: tgThemeParams.hint_color || 'var(--tg-theme-hint-color, #6c757d)' }}
            >
              В наличии: {stock}
            </p>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between mb-4">
            <button
              className="px-3 py-1 rounded"
              style={{
                backgroundColor: tgThemeParams.button_color || 'var(--tg-theme-button-color, #0088cc)',
                color: tgThemeParams.button_text_color || 'var(--tg-theme-button-text-color, #ffffff)',
              }}
              onClick={handleDecrease}
            >
              -
            </button>
            <span className="text-lg">{quantity}</span>
            <button
              className={`px-3 py-1 rounded ${quantity >= stock ? 'cursor-not-allowed' : ''}`}
              style={{
                backgroundColor: tgThemeParams.button_color || 'var(--tg-theme-button-color, #0088cc)',
                color: tgThemeParams.button_text_color || 'var(--tg-theme-button-text-color, #ffffff)',
              }}
              onClick={handleIncrease}
              disabled={quantity >= stock}
            >
              +
            </button>
          </div>
          <button
            className={`px-4 py-2 rounded-lg w-full ${quantity === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
            style={{
              backgroundColor: tgThemeParams.button_color || 'var(--tg-theme-button-color, #0088cc)',
              color: tgThemeParams.button_text_color || 'var(--tg-theme-button-text-color, #ffffff)',
            }}
            onClick={handleAddToCart}
            disabled={quantity === 0 || stock === 0}
          >
            В корзину
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
