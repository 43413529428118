import { useEffect, useState } from 'react';
import axios from 'axios';

// Utility function to determine if a color is "light"
const isLightColor = (color) => {
  const rgb = parseInt(color.slice(1), 16); // Convert hex to RGB
  const r = (rgb >> 16) & 0xff;
  const g = (rgb >> 8) & 0xff;
  const b = (rgb >> 0) & 0xff;
  // Calculate brightness according to the formula for perceived brightness
  const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
  return brightness > 186; // A threshold to determine if the color is light
};

// Function to get contrasting color (either black or white)
const getContrastingColor = (color) => (isLightColor(color) ? '#000000' : '#ffffff');

function CategoriesContainer({ setFilteredProducts }) {
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null); // Track the active category
  const tgThemeParams = window.Telegram?.WebApp?.themeParams || {};

  useEffect(() => {
    axios.get('/api/categories')
      .then(({ data }) => setCategories(data))
      .catch((error) => console.error('Error fetching categories:', error));
  }, []);

  const handleCategoryClick = (categoryId, productType) => {
    setActiveCategory(categoryId); // Set the clicked category as active
    
    // Fetch products by category
    axios.get(`/api/products?product_type=${productType}`)
      .then((response) => {
        setFilteredProducts(response.data); // Update the product list based on selected category
      })
      .catch((error) => {
        console.error('Error fetching products by category:', error);
      });
  };

  // Use dynamic colors based on Telegram themeParams
  const backgroundColor = tgThemeParams.bg_color || '#ffffff';
  const buttonColor = tgThemeParams.button_color || '#0088cc';
  const textColor = tgThemeParams.button_text_color || getContrastingColor(buttonColor);

  return (
    <div
      className="flex overflow-x-auto gap-2 p-2 hide-scrollbar"
      style={{
        backgroundColor,
        color: tgThemeParams.text_color || '#000000',
        height: '60px',
      }}
    >
      {categories.map(({ product_type_id, type_pic, type_name, product_type }) => (
        <button
          key={product_type_id}
          className={`flex items-center flex-shrink-0 p-1 rounded-lg shadow-md transition-all duration-500 ease-out transform ${
            activeCategory === product_type_id ? 'active' : ''
          } ${
            activeCategory === product_type_id 
              ? 'bg-gradient-to-r from-purple-500 to-indigo-500 scale-110 opacity-100' // Active animation
              : 'hover:scale-105 hover:opacity-90' // Hover effect for non-active
          }`}
          onClick={() => handleCategoryClick(product_type_id, product_type)} // Pass product type
          style={{
            backgroundColor: activeCategory === product_type_id 
              ? tgThemeParams.link_color || '#00aaff' // Active state color
              : buttonColor, // Dynamic button background
            color: textColor, // Dynamic contrasting text color
            width: '140px',
            height: '40px',
            transition: 'background-color 0.4s ease, transform 0.4s ease, opacity 0.4s ease',
          }}
        >
          {type_pic && (
            <img 
              src={type_pic} 
              alt={type_name} 
              className="w-6 h-6 mr-2" 
              style={{
                objectFit: 'contain',
              }}
            />
          )}
          <span className="text-xs font-medium">{type_name}</span>
        </button>
      ))}
    </div>
  );
}

export default CategoriesContainer;
