import React, { useState, useEffect } from 'react';
import axios from 'axios';

function CartModal({ onClose, cartItems, setCartItems, telegramUser }) {
  const [room, setRoom] = useState('');
  const [roomError, setRoomError] = useState(false);

  const tgThemeParams = window.Telegram?.WebApp?.themeParams || {};

  // Calculate total price and check for energy drink
  const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  const hasEnergyDrink = cartItems.some(item => item.product_type === 'energy_drinks');
  const deliveryFee = totalPrice < 1000 && !hasEnergyDrink ? 300 : 0;
  const finalPrice = totalPrice + deliveryFee;

  useEffect(() => {
    if (cartItems.length === 0) {
      onClose(); // Automatically close the modal if the cart is empty
    }
  }, [cartItems, onClose]);

  const handleConfirmOrder = async () => {
    if (!telegramUser) {
      console.error('Telegram user data is not available.');
      return;
    }

    if (!room) {
      setRoomError(true);
      return;
    }

    try {
      // Add or update user in the database
      await axios.post('/add-user/', {
        user_id: telegramUser.id,
        user_full_name: `${telegramUser.first_name} ${telegramUser.last_name}`,
        username: telegramUser.username,
      });

      // Prepare order data
      const orderData = {
        user_id: telegramUser.id,
        status: 'created',
        time_destination: 'now',
        destination_room: room,
        cart_id: 69, // Replace this with a dynamic value
        product_ids: cartItems.map(item => item.product_id).join(','),
        quantities: cartItems.map(item => item.quantity).join(','),
        sum: finalPrice,
      };

      // Send order data to the server
      await axios.post('/create-order/', orderData);

      setCartItems([]); // Clear the cart
      onClose(); // Close the modal
    } catch (error) {
      console.error('Error creating order:', error);
    }
  };

  const handleRemoveItem = (product_id) => {
    setCartItems(cartItems.filter(item => item.product_id !== product_id));
  };

  const handleClearCart = () => {
    setCartItems([]);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>
      
      <div
        className="bg-white rounded-lg p-6 z-10 max-w-md w-full relative"
        style={{
          backgroundColor: tgThemeParams.bg_color || 'var(--tg-theme-bg-color, #ffffff)',
          color: tgThemeParams.text_color || 'var(--tg-theme-text-color, #000000)',
        }}
      >
        <h2 className="text-xl font-semibold mb-4">Корзина</h2>

        <button
          className="absolute top-3 right-3 px-3 py-1 rounded-lg text-sm"
          style={{
            backgroundColor: tgThemeParams.destructive_color || 'var(--tg-theme-destructive-color, #ff4b4b)',
            color: tgThemeParams.button_text_color || 'var(--tg-theme-button-text-color, #ffffff)',
          }}
          onClick={handleClearCart}
        >
          Очистить корзину
        </button>

        {cartItems.length === 0 ? (
          <p>Ваша корзина пуста.</p>
        ) : (
          <div>
            <ul className="mb-4">
              {cartItems.map(item => (
                <li key={item.product_id} className="flex justify-between items-center mb-2">
                  <span>{item.product_name} x {item.quantity}</span>
                  <div className="flex items-center">
                    <span className="mr-2">{item.price * item.quantity} тг</span>
                    <button
                      onClick={() => handleRemoveItem(item.product_id)}
                      className="ml-2 px-2 py-1 rounded"
                      style={{
                        backgroundColor: tgThemeParams.hint_color || 'var(--tg-theme-hint-color, #6c757d)',
                        color: tgThemeParams.button_text_color || 'var(--tg-theme-button-text-color, #ffffff)',
                      }}
                    >
                      -
                    </button>
                  </div>
                </li>
              ))}
            </ul>

            <div className="flex justify-between font-semibold mb-4">
              <span>Итого товаров:</span>
              <span>{totalPrice} тг</span>
            </div>

            <div className="flex justify-between font-semibold mb-4">
              <span>{hasEnergyDrink ? 'Бесплатная доставка:' : 'Стоимость доставки:'}</span>
              <span>{deliveryFee} тг</span>
            </div>

            <div className="flex justify-between font-semibold mb-4">
              <span>Итого с доставкой:</span>
              <span>{finalPrice} тг</span>
            </div>

            <div className="mb-4">
              <label className="block mb-2">Укажите Рум:</label>
              <input
                type="text"
                value={room}
                onChange={(e) => { setRoom(e.target.value); setRoomError(false); }}
                className={`w-full p-2 border rounded ${roomError ? 'border-red-500' : 'border-gray-300'}`}
                style={{
                  backgroundColor: tgThemeParams.input_bg_color || '#f8f9fa',
                  color: tgThemeParams.input_text_color || '#000000',
                }}
                placeholder="Введите Рум"
                required
              />
              {roomError && <p className="text-red-500 mt-1">Поле Рум обязательно</p>}
            </div>

            <button
              className="px-4 py-2 rounded-lg w-full mb-2"
              style={{
                backgroundColor: tgThemeParams.button_color || 'var(--tg-theme-button-color, #0088cc)',
                color: tgThemeParams.button_text_color || 'var(--tg-theme-button-text-color, #ffffff)',
              }}
              onClick={handleConfirmOrder}
            >
              Подтвердить заказ
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default CartModal;
